export const basePath = '/';

export const menu = [{
  name: 'HOME',
  href: '',
  segment: 'home'
}, {
  name: 'サービス案内',
  href: 'service',
  segment: 'service'
}, {
  name: '会社情報',
  href: 'company',
  segment: 'company'
}, {
  name: '採用情報',
  href: 'recruit',
  segment: 'recruit'
}, {
  name: '整備工場・アクセス',
  href: 'access',
  segment: 'access'
}, {
  name: 'お問い合わせ',
  href: 'contact',
  segment: 'contact'
}];
